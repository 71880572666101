import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		userinfo: "",
		baiduToken: ""
	},
	mutations: {
		// 保存用户信息
		saveUserinfo(state, userinfo) {
			state.userinfo = userinfo;
		},
		saveBaiduToken(state, baiduToken) {
			state.baiduToken = baiduToken;
		},
	}
})