import axios from 'axios'
import { Message } from 'element-ui';
import router from '../router'


// 创建一个axios的实例
const Axios = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? "http://127.0.0.1:3007/":'https://api.aigfms.com/',
	timeout: 5000,
	headers: {
		'Content-Type': 'application/json;charset=utf-8'
	}
})

// 添加请求拦截
Axios.interceptors.request.use(
	config => {
		config.headers["token"] = localStorage.getItem("token");
		// 在发送请求之前做些什么
		return config
	},
	error => {
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)

// 添加响应拦截器
Axios.interceptors.response.use(
	response => {
		if(response.data.status == 1){
			Message.error(response.data.message);
		}
		if(response.status == 401 || response.data.sendStatus == 401){
			localStorage.removeItem("token")
			localStorage.removeItem("userinfo")
			router.replace({ name: 'login' })
		}
		// 对响应数据做些什么
		return response
	},
	error => {
		// 对响应错误做些什么
		return Promise.reject(error)
	}
)

export default Axios