import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: () => import( /* webpackChunkName: "home" */ '../views/home/home.vue'),
		meta: {
			title: "AI高分训练营",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "login" */ '../views/login/login.vue'),
		meta: {
			title: "登录",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/forget',
		name: 'forget',
		component: () => import( /* webpackChunkName: "forget" */ '../views/login/forget.vue'),
		meta: {
			title: "忘记密码",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/registered',
		name: 'registered',
		component: () => import( /* webpackChunkName: "registered " */ '../views/login/registered.vue'),
		meta: {
			title: "注册",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/read',
		name: 'read',
		component: () => import( /* webpackChunkName: "read " */ '../views/read/read.vue'),
		meta: {
			title: "面试必读",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/readiInfo',
		name: 'readiInfo',
		component: () => import( /* webpackChunkName: "readiInfo " */ '../views/read/index.vue'),
		meta: {
			title: "必读详情",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/buy',
		name: 'buy',
		component: () => import( /* webpackChunkName: "buy " */ '../views/buy/buy.vue'),
		meta: {
			title: "购买算力",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/personal',
		name: 'personal',
		component: () => import( /* webpackChunkName: "personal " */ '../views/personal/personal.vue'),
		meta: {
			title: "个人中心",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import( /* webpackChunkName: "about" */ '../views/about/about.vue'),
		meta: {
			title: "关于我们",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/graduate',
		name: 'graduate',
		component: () => import( /* webpackChunkName: "graduate" */ '../views/graduate/graduate.vue'),
		meta: {
			title: "考研面试",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/graduateTest',
		name: 'graduateTest',
		component: () => import( /* webpackChunkName: "graduateTest" */ '../views/graduate/graduateTest.vue'),
		meta: {
			title: "考研面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/graduateTest2',
		name: 'graduateTest2',
		component: () => import( /* webpackChunkName: "graduateTest2" */ '../views/graduate/graduateTest2.vue'),
		meta: {
			title: "考研面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/school',
		name: 'school',
		component: () => import( /* webpackChunkName: "school" */ '../views/school/school.vue'),
		meta: {
			title: "校招面试",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/schoolTest',
		name: 'schoolTest',
		component: () => import( /* webpackChunkName: "schoolTest" */ '../views/school/schoolTest.vue'),
		meta: {
			title: "校招面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/schoolTest2',
		name: 'schoolTest2',
		component: () => import( /* webpackChunkName: "schoolTest2" */ '../views/school/schoolTest2.vue'),
		meta: {
			title: "校招面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/social',
		name: 'social',
		component: () => import( /* webpackChunkName: "social" */ '../views/social/social.vue'),
		meta: {
			title: "社招面试",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/socialTest',
		name: 'socialTest',
		component: () => import( /* webpackChunkName: "socialTest" */ '../views/social/socialTest.vue'),
		meta: {
			title: "社招面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/socialTest2',
		name: 'socialTest2',
		component: () => import( /* webpackChunkName: "socialTest2" */ '../views/social/socialTest2.vue'),
		meta: {
			title: "社招面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/teacher',
		name: 'teacher',
		component: () => import( /* webpackChunkName: "teacher" */ '../views/teacher/teacher.vue'),
		meta: {
			title: "教师面试",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/teacherTest',
		name: 'teacherTest',
		component: () => import( /* webpackChunkName: "teacherTest" */ '../views/teacher/teacherTest.vue'),
		meta: {
			title: "教师面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/teacherTest2',
		name: 'teacherTest2',
		component: () => import( /* webpackChunkName: "teacherTest2" */ '../views/teacher/teacherTest2.vue'),
		meta: {
			title: "教师面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/public',
		name: 'public',
		component: () => import( /* webpackChunkName: "public" */ '../views/public/public.vue'),
		meta: {
			title: "公考面试",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/publicTest',
		name: 'publicTest',
		component: () => import( /* webpackChunkName: "publicTest" */ '../views/public/publicTest.vue'),
		meta: {
			title: "公考面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
	{
		path: '/publicTest2',
		name: 'publicTest2',
		component: () => import( /* webpackChunkName: "publicTest2" */ '../views/public/publicTest2.vue'),
		meta: {
			title: "公考面试训练",
			content:{
				keywords:"招聘,面试,面经,笔试,简历,社招,校招,教师招聘,研究生,公务员,事业编,公考,考公",
				description:"招聘面试面经笔试简历社招校招教师招聘研究生公务员事业编公考考公",
				verification:"25e17e68ddbe6a141c901145c047f67c",
			}
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router