import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import req from '@/utils/request.js';
import Vuex from 'vuex'
import store from './store/index';
import SlideVerify from 'vue-monoplasty-slide-verify'; 
Vue.use(SlideVerify)
Vue.use(ElementUI);
Vue.use(Vuex)

Vue.config.productionTip = false
Vue.prototype.http = req

/* main.js */
router.beforeEach((to, from, next) => {
	if (to.meta.content) {
		const head = document.getElementsByTagName('head')[0];
		// 删除现有名称为 "keywords" 和 "description" 的 meta 标签
		const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
		if (existingKeywordsMeta) {
			existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
		}
		const existingDescriptionMeta = document.querySelector('meta[name="description"]');
		if (existingDescriptionMeta) {
			existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
		}
		const existing360Meta = document.querySelector('meta[name="360-site-verification"]');
		if (existing360Meta) {
			existing360Meta.remove(); // 移除现有的关键词 meta 标签
		}

		// 创建并设置新的关键词和描述 meta 标签
		const keywordsMeta = document.createElement('meta');
		keywordsMeta.setAttribute('name', 'keywords');
		keywordsMeta.setAttribute('content', to.meta.content.keywords);
		head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

		const descriptionMeta = document.createElement('meta');
		descriptionMeta.setAttribute('name', 'description');
		descriptionMeta.setAttribute('content', to.meta.content.description);
		head.appendChild(descriptionMeta); // 添加新的描述 meta 标签
		
		const verificationMeta = document.createElement('meta');
		verificationMeta.setAttribute('name', '360-site-verification');
		verificationMeta.setAttribute('content', to.meta.content.verification);
		head.appendChild(verificationMeta); // 添加新的描述 meta 标签

		// 可选：如果需要，更新名为 "content" 的其他 meta 标签
		const contentMeta = document.querySelector('meta[name="content"]');
		if (contentMeta) {
			contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
		}
	}
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})

new Vue({
	router,
	store,
	render: h => h(App),
	mounted() {
		// 触发 renderAfterDocumentEvent
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')