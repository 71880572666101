<template>
	<router-view />
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		mounted() {
			document.dispatchEvent(new Event('render-event'))
		},
		methods: {

		}
	}
</script>

<style>
	body {
		background-color: white;
	}
</style>